import { Outlet, useMatches } from '@remix-run/react'

import Layout from '~/components/Layout'

type Matches = {
  data?: {
    page?: {
      data?: {
        flow?: string
      }
    }
    name: string
    logo: string
    ctaButtonLink?: string
  }
  handle?: {
    customLayoutLinks: {
      headerCTA?: string
      footerCTA?: string
    }
    isHeaderHidden?: boolean
  }
}

const SitePage = () => {
  const matches = useMatches()

  const landingPageData = (
    matches.find(
      match => match.id === 'routes/__site/$landingPath/index',
    ) as Matches
  )?.data

  const homePageData = (
    matches.find(match => match.id === 'routes/__site/index/index') as Matches
  )?.data

  const builderRedirectCTA = landingPageData?.page?.data?.flow
  const homeRedirectCTA = homePageData?.ctaButtonLink

  const overrideCTARedirect = homeRedirectCTA ?? builderRedirectCTA

  let partner: { name: string; logo: string } | undefined
  if (landingPageData?.name) {
    partner = {
      name: landingPageData.name,
      logo: landingPageData.logo,
    }
  }

  const customLayoutLinks = (matches as Matches[]).find(
    match => match.handle?.customLayoutLinks,
  )?.handle?.customLayoutLinks

  const isHeaderHidden = (matches as Matches[]).find(
    match => match.handle?.isHeaderHidden,
  )?.handle?.isHeaderHidden

  return (
    <Layout
      isHeaderHidden={isHeaderHidden}
      partner={partner}
      headerCTA={overrideCTARedirect ?? customLayoutLinks?.headerCTA}
      footerCTA={overrideCTARedirect ?? customLayoutLinks?.footerCTA}
    >
      <Outlet />
    </Layout>
  )
}

export default SitePage
